// ------------------------------ ESSENTIALS ------------------------------ //
@import 'ESSENTIALS/_vars';
@import 'ESSENTIALS/_reset';
@import 'ESSENTIALS/_typography';
@import 'ESSENTIALS/_elements';
@import 'ESSENTIALS/_animations';

// ------------------------------ //ESSENTIALS ------------------------------ //

// ------------------------------ SECTIONS ------------------------------ //
@import 'MAIN-SECTIONS/header';
@import 'MAIN-SECTIONS/main';
@import 'MAIN-SECTIONS/footer';
// ------------------------------ //SECTIONS ------------------------------ //

@import 'form';



@for $i from 1 through length($rooms) {
  .room-#{$i} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: $light;
    background-color: nth($rooms, $i); } }
