.hero {
  position: relative;
  overflow: hidden;
  display: grid;
  justify-items: center;
  height: 100vh;
  max-height: 100vh;
  padding: 0;
  a {
    z-index: 100;
    position: relative; } }


.hero-slide {
  img {
    max-width: none;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    transform: scale(1.4); } }

.hero-logo {
  filter: invert(1);
  margin-left: 15%;
  align-self: center;
  margin-right: 15%;
  max-width: 320px;
  position: relative; }

.scroll-icon {
  width: 1.5em;
  height: 1.5em;
  align-self: end;
  margin-bottom: 2.5vh;
  background-image: url($icons + "/arrow_down.svg");
  animation: scroll-arrow 2s infinite;
  opacity: 0; }


.hero-slider-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  background-image: radial-gradient(circle at top left, transparentize($dark, .7), transparentize($dark, .8));
  z-index: 100; }


@media (min-width: 768px) {
  .scroll-icon {
    margin-bottom: 7.5vh; }

  .hero-slider-overlay, .hero-logo {
    display: none; }

  .hero-slide img {
    transform: none; } }
