#szobak {
  padding: 0;
  img {
    pointer-events: none; }
  h2 {
    display: flex;
    justify-content: center;
    align-items: baseline;
    background-color: #a67c52;
    text-shadow: 2px 1px rgba($light, .2);
    margin-top: 0;
    &::after {
      display: block;
      content: 'Flóra ház';
      color: #57412b;
      font-size: .5em;
      margin-left: .2em;
      text-shadow: none; } } }

.button {
  cursor: pointer;
  transition: .1s ease-out; }



@for $i from 1 through length($rooms) {
  #szoba-#{$i} {
    // background-color: darken(nth($rooms, $i), 25)
    .button, .room-unavailable-reserve-btn:hover {
      background-color: darken(nth($rooms, $i), 10); }
    .button:hover {
      background-color: darken(nth($rooms, $i), 20); } } }

@import 'room';
