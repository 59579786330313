.stars-header {
  display: flex;
  align-items: baseline;
  h5 {
    margin-left: .5em; } }

.stars {
  display: flex;
  .star {
    polygon {
      fill: #d8d8d8; } }

  &[data-stars] {
    .star polygon {
      fill: #ffd055; } }


  &[data-stars="1"] {
    .star:nth-child(1) ~ .star polygon {
      fill: #d8d8d8; } }


  &[data-stars="2"] {
    .star:nth-child(2) ~ .star polygon {
      fill: #d8d8d8; } }


  &[data-stars="3"] {
    .star:nth-child(3) ~ .star polygon {
      fill: #d8d8d8; } }


  &[data-stars="4"] {
    .star:nth-child(4) ~ .star polygon {
      fill: #d8d8d8; } }


  &[data-stars="5"] {
    .star:nth-child(5) ~ .star polygon {
      fill: #d8d8d8; } } }



