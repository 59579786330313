.map {
  position: relative;
  height: 60vh;
  max-height: 400px; }

.bibic-marker {
  width: 20px;
  box-shadow: 0 0 10px transparentize($dark, .8);
  transform: translateX(-50%);
  background: $accent-color;
  padding: .5em;
  border-radius: 50%; }
