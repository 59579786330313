#visszajelzesek {
  // min-height: 0
  padding: 0 5% 10%;
  display: grid;
  h2 {
    margin-top: 0; }
  h3 {
    text-align: left;
    margin: .5em 0; } }

.room-feedbacks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  margin-top: 1em; }

.feedback-list {
  max-height: 50vh;
  min-height: 240px;
  overflow-y: scroll;
  border: 1px solid $light;
  border-radius: 12px;
  padding: 1em;
  display: grid;
  grid-gap: .7em; }

.feedback-list-element {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: .5em 1em;
  background-color: $light;
  border-radius: 4px;
  position: relative;
  @for $i from 1 through length($rooms) {
    &[data-room="#{$i}"] {
      border-bottom: 4px solid darken(nth($rooms, $i), 5);
      span {
        background-color: nth($rooms, $i); } } }

  .star:nth-child(1) ~ .star polygon {
    fill: #d8d8d8; }

  span {
    position: relative;
    min-width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; }
  p {
    overflow: hidden;
    color: $dark;
    margin-left: 1em;
    text-align: right;
    line-height: 1.1;
    margin-bottom: .5em; }
  h6 {
    color: $grey;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: .2em .5em;
    font-family: monospace; } }


@media (min-width: 1024px) {
  #visszajelzesek {
    padding: 0 10vw 5%;
    grid-template-columns: repeat(2, 1fr);
    h2 {
      grid-column: 1 / -1; } } }


@import 'feedback-form';
@import 'stars';

.feedback-done {
  display: grid;
  justify-items: center;
  align-content: center;
  min-height: 100vh;
  h1 {
    font-family: monospace;
    font-size: $accent-color-dark; } }
