.not-available {
  border: 1px solid $light;
  border-radius: 2em;
  padding: .5em 1.5em; }

.gallery {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap; }


.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  .spinner {
    animation: pulsate 1s infinite;
    background-color: $accent-color;
    border-radius: 50%;
    position: relative;
    width: 12px;
    height: 12px;
    margin-right: .5em; } }
