#arak {
  background-color: $accent-color-dark;
  padding: 2em 0;
  min-height: 100vh;

  h2 {
    margin-top: 0;
    text-align: center;
    text-shadow: none; }
  p {
    font-size: .8em;
    padding: 0 4em 0 2em; } }

.price-list, .price-list li {
  display: flex;
  flex-direction: column;
  align-items: center; }

.price-list {
  margin-top: 1em;
  li {
    border-radius: 12px;
    margin: 1em;
    @for $i from 1 through length($prices) {
      &:nth-child(#{$i}) .price-content {
        background-color: nth($prices, $i); } } }

  h3, h4, .price-button {
    text-transform: uppercase; } }

.price-content {
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  h3 {
    font-family: "White Festive", sans-serif;
    font-size: 3.75em;
    line-height: 1;
    margin-bottom: -.1em;
    span {
      font-size: .5em; } }
  h4 {
    font-size: 1.25em; }
  h5 {
    font-weight: lighter; } }

.price-button {
  border-radius: 1.5em;
  font-weight: bolder;
  font-family: "White Festive", sans-serif;
  padding: .2em 2em 0;
  font-size: 1.5em;
  margin-top: -1.5em;
  letter-spacing: .05em;
  background: $light;
  border: none;
  color: $accent-color-dark; }








.special, .special span {
  background: darken($lightred, 20);
  width: 160px;
  height: 160px;
  &::before, &::after {
    content: "";
    position: absolute;
    height: 160px;
    width: 160px;
    background: darken($lightred, 20); } }

.special {
  color: $light;
  padding: 4em 0;
  background-color: transparent !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
  position: relative;
  transform: rotate(15deg);
  a {
    color: $light;
    font-size: .9em; }
  h4, h5 {
    font-size: 3em;
    text-align: center;
    font-family: "White Festive", sans-serif;
    width: auto;
    margin: 0;
    position: relative;
    z-index: 1; }
  h4 {
    font-size: 4em;
    font-weight: bolder; }
  h5 {
    font-weight: lighter; }
  span {
    background: darken($lightred, 20);
    position: absolute;
    &::before {
      transform: rotate(18deg); }
    &::after {
      transform: rotate(36deg); } }


  &::before {
    background: darken($lightred, 20);
    transform: rotate(54deg); }
  &::after {
    transform: rotate(72deg); } }



@media (max-width: 639px) {
  .word-break {
    font-size: 1em !important; } }

@media (min-width: 640px) {
  .price-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    li {
      position: relative;
      width: 220px;
      height: 240px;
      background-color: $light;
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @for $i from 1 through length($prices) {
        &:nth-child(#{$i}) {
          .price-button {
            background-color: nth($prices, $i);
            &:hover {
              background-color: lighten(nth($prices, $i),10); } } } } } }

  .price-content {
    position: absolute;
    background-color: none !important;
    transform: translateY(-50%);
    transform-origin: top;
    width: 172px;
    height: 172px;
    top: 0;

    h3 {
      display: flex;
      flex-direction: column;
 }      // font-size: 3.5em
    h4, h5 {
      position: absolute;
      top: 0;
      color: $dark; }
    h4 {
      margin-top: 210px; }
    h5 {
      margin-top: calc(210px + 1.5em); } }

  .price-button {
    color: $light;
    margin-bottom: .5em;
    padding: .5em 1.5em;
    transition: .2s ease-in; } }

@media (min-width: 1024px) {
  #arak {
    min-height: calc(90vh - 60px);
    background: {
      image: linear-gradient(transparentize($dark, .7), transparentize($dark, .7)), url(#{$bg}prices.jpg);
      position: center;
      size: cover; } }
  .special {
    position: absolute !important;
    bottom: 2vw; } }


@media (min-width: 1024px) and (orientation: landscape) {
  @media (min-aspect-ratio: 16/9 ) {
    #arak {
      padding: 0 0 2em 0;
      min-height: 0;
      height: calc(100vh - 60px - 2em);
      p {
        position: absolute;
        bottom: 1.5em;
        left: 50%;
        transform: translateX(-50%); } }

    .price-list {
      margin-top: calc(80px + 2em);
      // justify-content: space-evenly
      li {
        width: 192px;
        height: 220px;
        margin: 0 2vw; } }

    .price-content {
      h4 {
        margin-top: 180px; }
      h5 {
        margin-top: calc(180px + 1.5em); } } }
  .special {
    transform: scale(.8) rotate(15deg);
    transform-origin: left;
    left: 2vw;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: .2s ease-out;
    &:hover {
      transform: scale(.9) rotate(15deg); } } }

