@keyframes scroll-arrow {
  0% {
    opacity: 0;
    transform: translateY(-50%); }
  80%, 100% {
    opacity: 1;
    transform: translateY(50%); } }

@keyframes pulsate {
  100% {
    opacity: 0;
    transform: scale(1.8); } }


@keyframes chat {
  0%, 25%, 100% {
    transform: none; }
  5% {
    transform: scale(1.1); }
  10% {
    transform: scale(1.1) rotate(-21deg); }
  15% {
    transform: scale(1.075) rotate(14deg); }
  20% {
    transform: scale(1.05) rotate(-7deg); }
  // 70%
 }  //   transform: rotate(15deg)


@keyframes animate-back {
  0% {
    filter: none;
    opacity: 1;
    z-index: 99;
    bottom: 0; }
  40% {
    transform: scale(.95) translateX(90%); }
  100% {
    transform: scale(.9);
    z-index: 9;
    opacity: .9;
    filter: blur(2px);
    bottom: -20%;
    opacity: .4; } }

@keyframes animate-forward {
  0% {
    transform: scale(.9);
    z-index: 9;
    opacity: .9;
    filter: blur(2px);
    bottom: -20%; }
  40% {
    transform: scale(.95); }
  100% {
    bottom: 0;
    transform: none;
    z-index: 99; } }
