.carousel-wrapper {
  padding: 0;
  min-height: 0;
  height: 100vh;
  position: static;
  display: grid;
  grid-template-rows: auto 1fr;
  h2 {
    margin: 1rem 0 0;
    padding: 0;
    text-align: center; }
  h3 {
    font-size: 1rem;
    margin: 1rem auto .5rem;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif; } }

.carousel {
  position: relative;
  >div, .react-reveal {
    display: flex;
    flex: 1; } }

.carousel-item {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  span {
    position: absolute;
    width: 100%;
    height: 100%;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent 65%, black 100%);
      z-index: -1;
      opacity: .7; } } }

// .carousel-item-picture
//   padding-top: 64px

.carousel-item-desc {
  padding: 1em;
  h4 {
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: .1em;
    text-align: left; } }

.carousel-item-bg {
  position: absolute;
  z-index: -1;
  opacity: .5;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(4px); }

.carousel-arrow {
  box-shadow: 0 0 5px black;
  --size: 48px;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  display: grid;
  place-items: center;
  background-color: $accent-color;
  border-radius: 50%;
  margin: 6px 1rem;
  position: absolute;
  z-index: 1000;
  top: -64px; }

.arrow-icon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #fff; }

.carousel-arrow-prev .arrow-icon {
  transform: rotate(180deg); }

.carousel-arrow-next {
  right: 0; }


@media (min-aspect-ratio: 3/4) {
  .carousel-wrapper {
    height: 100vh;
    h3 {
      max-width: 50vw;
      margin-bottom: 3rem; } }
  .carousel-item {
    max-width: 640px;
    max-height: 540px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    grid-template-rows: 1fr;
    span {
      display: none; } }

  .carousel-item-desc {
    background: linear-gradient(rgba(0,0,0, .8), transparent);
    padding-right: 25%; }

  .carousel-item-picture {
    z-index: -1;
    padding-top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: none; } } }


@media (min-width: 860px) {
  .carousel {
    overflow: hidden;
    width: 860px;
    margin: 0 auto 10vh; }
  .carousel-arrow {
    top: calc(50% - 1em);
    transform: translateY(-50%); }

  .carousel-item-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(transparent, rgba(0,0,0, .7) 80%); } }
