#napraforgo {
  background-color: darken(#cc9900, 10);
  position: relative;
  overflow: hidden;

  .gallery {
    margin: 2em 0;
    img {
      padding: 1em;
      max-height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box; } } }
.cert-bg {
  display: none; }


@media (min-width: 480px) {
  #napraforgo {
    p {
      margin: auto;
      max-width: 480px; } } }

@media (min-width: 760px) {
  #napraforgo {
    background-color: #cc9900; } }

@media (min-aspect-ratio: 3/2) {
  #napraforgo {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    background-color: transparent;
    .gallery {
      justify-content: flex-end;
      img {
        transition: .2s;
        margin-right: 1em;
        &:hover {
          transform: scale(1.05); } } }
    p {
      padding: 1em;
      margin: 0;
      text-align: right; } }
  .cert-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparentize($dark, .4); } } }

@media (min-width: 1280px) {
  #napraforgo {
    padding-right: 7.5vw; } }
