.menu {
  display: grid;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  grid-template-areas: "main-nav room-nav";
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr; }

.menu-logo {
  display: none; }

nav, .room-menu {
  transition: .5s; }

nav {
  grid-area: main-nav;
  background-color: $light;
  display: flex;
  justify-content: center;
  ul, li, a {
    display: flex;
    flex: 1; }
  ul {
    margin: 0;
    flex-direction: column;
    justify-content: space-evenly; }
  a {
    text-transform: uppercase;
    letter-spacing: .05em;
    align-items: center;
    padding: 1rem 0 1rem 2rem;
    color: $accent-color-dark;
    font-family: "White Festive", sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    &:hover {
      color: $accent-color; } } }

.room-menu {
  grid-area: room-nav;
  background-color: transparentize($accent-color-dark, .2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    color: $light;
    position: relative;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    font-size: 1.5em; }

  li {
    margin: .5em;
    border-radius: 50%;
    transition: .3s;
    @for $i from 1 through length($rooms) {
      &:nth-child(#{$i}) {
        background-color: darken(nth($rooms, $i), 10);
        &:hover {
          background-color: darken(nth($rooms, $i), 20); }
        a {
          color: $light !important; } } } } }

.menu-hidden {
  pointer-events: none;
  .hamburger {
    pointer-events: all; }
  nav {
    transform: translateX(-100%); }
  .room-menu {
    transform: translateX(100%); } }

@import "hamburger";

@media (min-aspect-ratio: 7/6), (min-width: 768px) {
  .menu {
    grid-template-areas: "main-nav" "room-nav";
    grid-template-rows: 96px 80px;
    grid-template-columns: auto;
    nav ul, .room-menu {
      flex-direction: row;
      flex-wrap: wrap; }
    nav {
      ul {
        justify-content: center;
        align-items: flex-end;
        margin: 0; }
      li {
        flex: none; }
      a {
        font-size: 3.5vw;
        padding: 0 .5em; } } }
  .room-menu {
    a {
      font-size: 3vw; } } }

@media (min-width: 768px) {
  .menu {
    grid-template-areas: "menu-logo" "main-nav" "room-nav";
    height: auto;
    grid-template-rows: auto;
    nav {
      padding: 1em 0;
      a {
        padding: 0 .3em;
        font-size: 1.75em; } } }


  .room-menu {
    background-color: transparent;
    transform: translateY(-100%) !important;
    z-index: -1;
    a {
      font-size: 1.25em; } }

  .room-menu-show {
    transform: none !important; }

  .menu-hidden {
    nav, .room-menu {
      transform: none; } }

  .menu {
    pointer-events: none;
    li {
      pointer-events: all; } }

  .menu-logo {
    background-color: $light;
    grid-area: menu-logo;
    display: block;
    text-align: center;
    position: relative;
    z-index: -1;
    height: 48px;
    img {
      height: 80%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      position: absolute; } } }

@media (min-width: 1024px) {
  .menu nav a {
    padding: 0 1em; }
  .menu-logo {
    height: 116px; }
  .menu-logo-scrolled {
    height: 0; } }
