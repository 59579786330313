
.rdrCalendarWrapper {
  max-width: 7 * 48px;
  font-family: "Coolvetica" , sans-serif;
  letter-spacing: .1em !important;
  select {
    letter-spacing: .05em !important;
    font-family: "Coolvetica" , sans-serif; } }
.rdrMonth {
  flex: 1; }
.rdrMonthsVertical {
  display: flex; }
.rdrDateRangePickerWrapper {
  margin: 0 auto; }
.rdrDateDisplayWrapper,
.rdrDefinedRangesWrapper {
  display: none; }

.rdrMonthAndYearWrapper {
  display: flex;
  justify-content: center;
  align-items: center; }



.rdrMonthAndYearWrapper button {
  background: $accent-color !important;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 1em; }

.rdrNextButton i {
  border-color: transparent transparent transparent white !important;
  margin-left: 14px !important; }

.rdrPprevButton i {
  border-color: transparent white transparent transparent !important; }

.rdrDayToday .rdrDayNumber span:after {
  background: $accent-color-dark !important; }

.rdrDays, .rdrWeekDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr); }
.rdrWeekDays {
  justify-items: center; }

.rdrDay {
  position: relative;
  min-height: 48px; }

.rdrDayNumber {
  color: $dark;
  font-family: "Coolvetica" , sans-serif;
  letter-spacing: .1em; }

.rdrDayPassive, .rdrDayDisabled {
  user-select: none;
  cursor: not-allowed;
  background-color: grey !important;
  opacity: .1;
  span {
    color: black !important; } }

.rdrDayEndOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrInRange {
  border-radius: 0 !important; }

.rdrStartEdge, .rdrDayStartOfMonth .rdrInRange {
  border-top-left-radius: 3em !important;
  border-bottom-left-radius: 3em !important; }

.rdrEndEdge, .rdrDayEndOfMonth .rdrInRange {
  border-top-right-radius: 3em !important;
  border-bottom-right-radius: 3em !important; }

@media (min-width: 640px) {
  .rdrCalendarWrapper {
    background-color: transparentize($light, .2);
    border-radius: 1em;
    overflow: hidden; }
  .rdrMonth {
    padding: 0 !important; } }

@media (min-width: 720px) {
  .rdrDateRangePickerWrapper {
    margin: 0; } }
