
#szolgaltatasok .gallery, .services-footer, #szolgaltatasok h2 {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto; }

#szolgaltatasok {
  padding: 2.5%;
  h2 {
    margin-left: 5%;
    width: 100%;
    margin-top: 0; }
  .gallery {
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(auto-fill, calc(1024px / 3 - 2em)); }

  .gallery-item {
    color: $dark;
    background-color: $light;
    img {
      min-height: 160px;
      user-select: none; }
    .img-wrapper {
      overflow: hidden;
      max-height: calc(320px * 0.54);
      display: flex;
      align-items: flex-end;
      img {
        width: 100%;
        margin: auto; } }
    h3 {
      font-size: 1em;
      padding: .5em 1em;
      text-align: left;
      font-style: italic;
      letter-spacing: .05em; }
    p {
      padding: 0 1em 1em; } } }

.services-footer {
  width: 100%;
  margin-top: 3em;
  text-align: right; }

@media(min-width: 1024px) {
  #szolgaltatasok {
    h2 {
      margin-left: auto; } } }
