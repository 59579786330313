body, ul, h1, h2, h3, h4, h5, p, textarea, input, fieldset {
  margin: 0;
  padding: 0; }
a {
  text-decoration: none;
  cursor: pointer; }
li {
  list-style-type: none; }
img {
  display: block;
  color: black;
  max-width: 100%; }

section {
  overflow: hidden;
  padding: 7.5%;
  position: relative;
  background-color: $accent-color-dark;
  min-height: 70vh;
  @media (min-width: 768px) {
    background-image: url($images + "bg/bg.png"); } }

textarea, input, fieldset {
  border: none; }

textarea, input, button {
  &:focus {
    outline: none; } }

button {
  border: none;
  cursor: pointer; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
